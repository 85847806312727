import { IPredefinedTheme } from "../../Themes";
import img1 from "./screenshot.png";

export const Theme: IPredefinedTheme = {
  name: "Iron Man",
  credit: "Matti#2942",
  description: "Mark 42. Threw a little hot rod red in there.",
  reference: "https://discord.com/channels/415207508303544321/921991895230611466/1069233364927787089",
  screenshot: img1,
  colors: {
    primarylight: "#FFFEFC",
    primary: "#FFA95E",
    primarydark: "#E8BC71",
    successlight: "#00FF00",
    success: "#0c0",
    successdark: "#090",
    errorlight: "#FF0B0B",
    error: "#FF0000",
    errordark: "#770000",
    secondarylight: "#FFC596",
    secondary: "#DA3F3F",
    secondarydark: "#6E1D1D",
    warninglight: "#ff0",
    warning: "#cc0",
    warningdark: "#990",
    infolight: "#FFFFFF",
    info: "#B6E9FF",
    infodark: "#5596CF",
    welllight: "#8A2121",
    well: "#350000",
    white: "#fff",
    black: "#000",
    hp: "#FF4E4E",
    money: "#C2F26F",
    hack: "#D5FFB2",
    combat: "#E39C5A",
    cha: "#CA4444",
    int: "#6495ed",
    rep: "#E39C5A",
    disabled: "#3C0C0C",
    backgroundprimary: "#2C0707",
    backgroundsecondary: "#551212",
    button: "#8A2121",
  },
};
